import React from "react";
import classes from "./Menu.module.css";
import MenuItem from "./MenuItem";
import DUMMY_COCKTAILS from "../../devData/dummyCocktails.json";
import backgroundImage2 from "../../assets/images/pexels-cottonbro-studio-5921123 copy.jpg";
import backgroundImage1 from "../../assets/images/pexels-cottonbro-studio-3338537 copy.jpg";
import backgroundImage3 from "../../assets/images/pexels-karolina-grabowska-4021983 copy.jpg";
import backgroundImage4 from "../../assets/images/cocktailimage.jpg";
import backgroundImage5 from "../../assets/images/pexels-cottonbro-studio-3297882 copy.jpg";
import backgroundImage6 from "../../assets/images/pexels-karolina-grabowska-4021983 copy.jpg";
import { useState } from "react";

import grilledGreens from "../../assets/images/photos/Grilled Greens.jpg";
import oysterMush from "../../assets/images/photos/Oyster Mushroom.jpg";
import tofu from "../../assets/images/photos/Tofupokki.jpg";
import loadedFries from "../../assets/images/photos/Loaded Fries.jpg";
import karaage from "../../assets/images/photos/Overpressure Karaage.jpg";
import porkJowl from "../../assets/images/photos/Pork Jowl.jpg";
import shortRib from "../../assets/images/photos/Shortrib.jpg";
import eggplant from "../../assets/images/photos/Miso Eggplant.jpg";

import oysters from "../../assets/images/photos/Oysters.jpg";
import shrimp from "../../assets/images/photos/Shrimp Cocktail.jpg";
import pickles from "../../assets/images/photos/House Pickles.jpg";
import cucumber from "../../assets/images/photos/Cucumber Salad.jpg";

import pearl from "../../assets/images/photos/Harajuku Pearl.jpg";
import mochi from "../../assets/images/photos/Harajuku Pearl.jpg";
import cotton from "../../assets/images/photos/Kurogama Cotton.jpg";
import toast from "../../assets/images/photos/Nori Honey Toast.jpg";

import cosmo from "../../assets/images/photos/Cosmopop.jpg";
import powerpuff from "../../assets/images/photos/Powerpuff Pisco.jpg";
import peach from "../../assets/images/photos/Peach Punks.jpg";
import branca from "../../assets/images/photos/Branca Bouquet.jpg";
import ricey from "../../assets/images/photos/Ricey Dicey.jpg";
import amari from "../../assets/images/photos/Amari Kano.jpg";
import scarecrow from "../../assets/images/photos/Scarecrow_s Harvest.jpg";
import tea from "../../assets/images/photos/Tea for One.jpg";
import queen from "../../assets/images/photos/Queen of Hearts.jpg";
import pandan from "../../assets/images/photos/Pandan o Rama.jpg";
import tobermory from "../../assets/images/photos/Tobermory Trail.jpg";
import shishito from "../../assets/images/photos/Holy Shishito.jpg";
import tropic from "../../assets/images/photos/Tropic Pursuit.jpg";
import verde from "../../assets/images/photos/Double Verde Colada.jpg";
import pear from "../../assets/images/photos/Shiso Pear Martini.jpg";
import ground from "../../assets/images/photos/From The Ground Up.jpg";
import raised from "../../assets/images/photos/Raised By Wolves.jpg";
import yoichi from "../../assets/images/photos/Yoichi The Killer.jpg";
import standing from "../../assets/images/photos/Standing Tall.jpg";
import parrallel from "../../assets/images/photos/Parallel Universe 1.jpg";

import apero1 from "../../assets/images/photos/Apero Hour 1.jpg";
import apero2 from "../../assets/images/photos/Apero Hour 2.jpg";
import blossoms1 from "../../assets/images/photos/Blossoms in the Sand 1.jpg";
import blossoms2 from "../../assets/images/photos/Blossoms in the Sand 2.jpg";

import flat from "../../assets/images/photos/Flat Iron Steak 1.jpg";
import flat2 from "../../assets/images/photos/Flat Iron Steak 2.jpg";

import hok from "../../assets/images/photos/Hokkaido Glacier 1.jpg";
import hok2 from "../../assets/images/photos/Hokkaido Glacier 2.jpg";

import smok from "../../assets/images/photos/Smoke and Mirrors 1.jpg";
import smok2 from "../../assets/images/photos/Smoke and Mirrors 2.jpg";
import smoo from "../../assets/images/photos/Smooth Operator 1.jpg";
import smoo2 from "../../assets/images/photos/Smooth Operator 2.jpg";

import was from "../../assets/images/photos/Wasabi Please.jpg";
import yog from "../../assets/images/photos/Yogogi Pop 1.jpg";
import yog2 from "../../assets/images/photos/Yogogi Pop 2.jpg";


import { addCocktailVariants } from "../../config/config";
import { motion } from "framer-motion";

const images = [
  backgroundImage1,
  backgroundImage2,
  backgroundImage3,
  backgroundImage4,
  backgroundImage5,
  backgroundImage6,
];

const titles = [
  "cocktails on tap",
  "izakaya food",
  "stirred cocktails",
  "shaken cocktails",
];

const cocktails = [
  // {
  //   title: "Yoyogi pop",
  //   ings: "BARLEY SHOCHU, VANILLA + YOGHURT LIQUEUR, 3X GRAPE WINE, EUCALYPTUS, VERJUS, CUMIN, DILL",
  //   image: yog2,
  //   type: "Signature Cocktails",
  //   price: 18
  // },
  {
    title: "G.A.T.",
    ings: "ALTOS BLANCO TEQUILA, WHITE WINE, PASTIS, GREEN APPLE, SENCHA TEA",
    image: null,
    type: "Signature Cocktails",
    price: 15
  }, 
  {
    title: "Golden Girl",
    ings: "MASUMI SAKE, UMESHU PLUM WINE, GOLDEN APPLE, SENCHA TEA",
    image: null,
    type: "Signature Cocktails",
    price: 15
  }, 
  {
    title: "Peachy Keen",
    ings: "JOHNNIE WALKER BLACK, DEWAR'S BLENDED, SCOTCH, PEACH, GENMAICHA TEA",
    image: null,
    type: "Signature Cocktails",
    price: 15
  }, 
  {
    title: "Oolong Fog",
    ings: "APPLETON'S RUM, KUMQUAT, YOGURT, OOLONG TEA",
    image: null,
    type: "Signature Cocktails",
    price: 15
  }, 
  {
    title: "Grape Crush",
    ings: "MASUMI RICE SHOCHU, CALPICO, KYOHO GRAPE",
    image: null,
    type: "Signature Cocktails",
    price: 15
  }, 
  {
    title: "Hokkaido Glacier",
    ings: "SOTOL, BARLEY SHOCHU, YUZU CORDIAL, COCONUT SODA, TONIC, SEASONED BLACK RICE CHIP",
    image: hok,
    type: "Signature Cocktails",
    price: 17
  }, 
  // {
  //   title: "Powerpuff Pisco",
  //   ings: "pisco, junmai ginjo sake, aromatized wine, hyssop + lychee soda, absinthe",
  //   image: powerpuff,
  //   type: "Signature Cocktails",
  //   price: 19
  // },
  {
    title: "tobermory trail",
    ings: "gin, mastiha, dry vermouth, palo santo, aloe, clarified citrus, cedar mist, blue",
    image: tobermory,
    type: "Signature Cocktails",
    price: 18
  },
  {
    title: "Tropic Pursuit",
    ings: "Anejo tequila, cachaca, yellow chartreuse, fino sherry, lime leaf, passionfruit, mango",
    image: tropic,
    type: "Signature Cocktails",
    price: 18
  },
  {
    title: "Strawberry Dream Team",
    ings: "CITADELLE GIN, FINO EN RAMA, STRAWBERRY, MARASHINO, LEMON, WHEY, MATCHA FOAM",
    image: null,
    type: "Signature Cocktails",
    price: 17
  },
  {
    title: "queen of hearts",
    ings: "brandy, amaro, reaspberry, white chocolate, lemon, sencha, whey",
    image: queen,
    type: "Signature Cocktails",
    price: 18
  },
  {
    title: "Blossoms in the Sand",
    ings: "PREMIUM JAPANESE GIN, TOASTED COCONUT, UMESHU, COCCHI ROSA, MANZANILLA SHERRY, BAIJU, AMARETTO, BUTTER MOCHI",
    image: blossoms2,
    type: "Signature Cocktails",
    price: 19
  },
  {
    title: "Shiso Pear Martini",
    ings: "vodka, shochu, shiso vermouth, pear, orange bitters",
    image: pear,
    type: "Signature Cocktails",
    price: 19
  },
  {
    title: "holy shishito",
    ings: "blanco tequila, strega, shishito, black cardamom, cucumber, lime, mole, togarashi",
    image: shishito,
    type: "Signature Cocktails",
    price: 18
  },
  {
    title: "Island Stray",
    ings: "PLANTERAY RUM, APPLETON AGED RUM, APEROL, CHERRY BARK, ORANGE, PASSIONFRUIT, VANILLA, CINNAMON, ANGOSTURA BITTERS",
    image: null,
    type: "Signature Cocktails",
    price: 18
  },
  // {
  //   title: "Apero Hour Every Hour",
  //   ings: "PREMIUM SWEET VERMOUTH, CAMPARI, AMONTILLADO SHERRY, CLARIFIED ORANGE, OLIVE LEAF SODA",
  //   image: apero1,
  //   type: "Signature Cocktails",
  //   price: 19
  // },
  // {
  //   title: "Smoke & Mirrors",
  //   ings: "FINO EN RAMA SHERRY, WHITE PORT, PERNOD, SMOKED TOMATO, RHUBARB CORDIAL, BALSAMIC PAINT",
  //   image: smok,
  //   type: "Signature Cocktails",
  //   price: 20
  // },
  {
    title: "wasabi please",
    ings: "CACHACA, ELDERFLOWER LIQUEUR, HONEYDEW, FERMENTED CELERY, WASABI, UMAMI BITTERS, LIME, PICKLED MELON DISC",
    image: was,
    type: "Signature Cocktails",
    price: 18
  },
  // {
  //   title: "ricey dicey",
  //   ings: "japanese whisky, blanco vermouth, ginger, bergamot, genmaicha soda, nori",
  //   image: ricey,
  //   type: "Signature Cocktails",
  //   price: 21
  // },
  {
    title: "smooth operator",
    ings: "JAPANESE WHISKY, BANANA LIQUEUR, CHOCOLATE + CARAMEL GENTIAN, HOJICHA, PINEAPPLE",
    image: smoo2,
    type: "Signature Cocktails",
    price: 19
  },
  {
    title: "peach punks",
    ings: "bourbon, prach aperitif, benedictine, blackened lemon cordial, bay leaf soda, corn pop dust",
    image: peach,
    type: "Signature Cocktails",
    price: 18
  },
  {
    title: "Yoichi the killer",
    ings: "premium japanese whisky, plum wine, vetiver cordial, black sugar, bitters, turmeric meringue",
    image: yoichi,
    type: "Signature Cocktails",
    price: 26
  },
  {
    title: "raised by wolves",
    ings: "mezcal, cynar, sherry blend, strawberry, curry leaf, coffee bitters",
    image: raised,
    type: "Signature Cocktails",
    price: 20
  },
  {
    title: "Negroni Ume",
    ings: "UMESHU, MASTIHA, APEROL, CAMPARI, CURACAO, COCCHI ROSA, HINOKI BITTERS",
    image: null,
    type: "Signature Cocktails",
    price: 19
  },
  {
    title: "Sesame Espresso-tini",
    ings: "PLANTERAY RUM, TIA MARIA COFFEE LIQUEUR, BLACK SESAME, COCONUT, PASSIONFRUIT, COLD BREW",
    image: null,
    type: "Signature Cocktails",
    price: 17
  },
  {
    title: "Old Fashioned Granny",
    ings: "BUSHMILLS IRISH WHISKEY, BROWN BUTTER, HOJICHA, BLACK PEACH CORDIAL, MANGO, CHOCOLATE BITTERS",
    image: null,
    type: "Signature Cocktails",
    price: 19
  },

  // {
  //   title: "pandan-o-rama",
  //   ings: "rum blend, pandan falernum, taro, rice milk, thai tea, miso + sake sabayon, whey",
  //   image: pandan,
  //   type: "Signature Cocktails",
  //   price: 20
  // },
  {
    title: "standing tall",
    ings: "non-alc aperitivo, grapefruit + lime codial, marigold + cardamom soda",
    image: standing,
    type: "Signature Cocktails",
    price: 12
  },
  {
    title: "Yuzu Collins",
    ings: "YUZU, CANE SUGAR, HOJICHA TEA",
    image: null,
    type: "Signature Cocktails",
    price: 12
  },
  // {
  //   title: "Parallel Universe",
  //   ings: "non-alc amaro, pomegranate, mushroom, mugicha tea",
  //   image: parrallel,
  //   type: "Signature Cocktails",
  //   price: 16
  // },
  // {
  //   title: "branca bouquet",
  //   ings: "fernet, amaro, blueberry, lemon, jasmine, soda, clarified w/ soy",
  //   image: branca,
  //   type: "Signature Cocktails",
  // },

  // {
  //   title: "amari kano",
  //   ings: "campari, sweet vermouth, amontillado sherry, amaro nonino, cascara soda",
  //   image: amari,
  //   type: "Signature Cocktails",
  // },
  // {
  //   title: "scarecrow's harvest",
  //   ings: "cognac, umeshu plum wine, cherry bark, marigold + cardamom soda",
  //   image: scarecrow,
  //   type: "Signature Cocktails",
  // },
  // {
  //   title: "tea for one",
  //   ings: "scotch, junmai sake, pomegranate, mushroom, mugicha, dubonnet",
  //   image: tea,
  //   type: "Signature Cocktails",
  // },





  // {
  //   title: "Double verde colada",
  //   ings: "white rum, green chatreuse, calpico, toasted pumpkin seed, 3 X coconut, fermented and fresh pineapple, matcha",
  //   image: verde,
  //   type: "Signature Cocktails",
  // },

  // {
  //   title: "from the ground up",
  //   ings: "rye, akvavit, red bitter blend, beetroot vermouth, maraschino, celery bitters, peychauds",
  //   image: ground,
  //   type: "Signature Cocktails",
  // },



];

const hotFood = [
  {
    title: "Grilled Mushrooms",
    ings: "oyster mushrooms, Maple, soy, sesame, leek",
    image: null,
    type: "Hot Food",
    price: 14
  },
  {
    title: "Grilled Flat Iron",
    ings: "6oz flat iron, Ponzu, daikon, green onion",
    image: null,
    type: "Hot Food",
    price: 27
  },
  {
    title: "Chicken Yakitori (2pcs)",
    ings: "Yakitori tare, green onion, black garlic mayo",
    image: null,
    type: "Hot Food",
    price: 10
  },
  {
    title: "Veggie Yakitori (2pcs)",
    ings: "zucchini, king oyster mushroom, black garlic mayo",
    image: null,
    type: "Hot Food",
    price: 10
  },
  // {
  //   title: "Loaded Fries (Vegetarian)",
  //   ings: "Furikake, ketchup, house mayo, curry sauce",
  //   image: loadedFries,
  //   type: "Hot Food",
  // },
  // {
  //   title: "Overpressure Karaage",
  //   ings: "Cured egg yolk, shichimi, lemon slaw, house mayo, (nuts / citrus)",
  //   image: karaage,
  //   type: "Hot Food",
  // },
  // {
  //   title: "Underpressure Pork Jowl",
  //   ings: "Sticky hot kecap sauce, perilla",
  //   image: porkJowl,
  //   type: "Hot Food",
  // },
  // {
  //   title: "Flat Iron Steak",
  //   ings: "6oz PEI flat iron, ponzu sauce, daikon, green onion",
  //   image: flat,
  //   type: "Hot Food",
  // },
  // {
  //   title: "Miso Eggplant",
  //   ings: "Eggplant, soy, butter, cream, miso",
  //   image: eggplant,
  //   type: "Hot Food",
  // },
];

const friedFood = [
  {
    title: "Overpressure Karaage",
    ings: "fried chicken, Shichimi, cured egg yolk, lemon slaw, tahini mayo",
    image: karaage,
    type: "Hot Food",
    price: 14
  },
  {
    title: 'Tofu"Yaki"',
    ings: "tofu, Takoyaki sauce, japanese mayo, green onion, bonito",
    image: null,
    type: "Hot Food",
    price: 12
  },
  {
    title: "Corn Croquettes",
    ings: "Corn puree, chili oil, leek salt, nasturtium",
    image: null,
    type: "Hot Food",
    price: 9
  },
 
  {
    title: "Loaded Curry Fries",
    ings: "crinkled potatoes, Furikake, japanese curry, cheese curds",
    image: null,
    type: "Hot Food",
    price: 12
  },  {
    title: "Chubby potato",
    ings: "Japanese sweet potato, mitarashi soy glaze, butter",
    image: null,
    type: "Hot Food",
    price: 9
  },
  {
    title: "Pig Mac (Katsu Sando)",
    ings: "Fried pork, red cabbage, special sauce, shokupan bread",
    image: null,
    type: "Hot Food",
    price: 14
  },
  {
    title: "Bacon mushroom udon",
    ings: "udon, bacon, mushroom, miso, soy, furikake, sesame",
    image: null,
    type: "Hot Food",
    price: 14
  },
  {
    title: "Crispy Tuna Onigiri (3pcs)",
    ings: "wild yellowfin, lacto chili, mayo, rice",
    image: null,
    type: "Hot Food",
    price: 14
  },
  
  // {
  //   title: "Underpressure Pork Jowl",
  //   ings: "Sticky hot kecap sauce, perilla",
  //   image: porkJowl,
  //   type: "Hot Food",
  // },
  // {
  //   title: "Flat Iron Steak",
  //   ings: "6oz PEI flat iron, ponzu sauce, daikon, green onion",
  //   image: flat,
  //   type: "Hot Food",
  // },
  // {
  //   title: "Miso Eggplant",
  //   ings: "Eggplant, soy, butter, cream, miso",
  //   image: eggplant,
  //   type: "Hot Food",
  // },
];

const coldFood = [
  // {
  //   title: "Oysters Dozen / Half Dozen",
  //   ings: "Leek mignonette pink peppercorn, house hot sauce, wasabi",
  //   image: oysters,
  //   type: "Cold Food",
  // },
  // {
  //   title: "Shrimp Cocktail (Pescatarian)",
  //   ings: "Tiger shrimp, yuzu cocktail sauce",
  //   image: shrimp,
  //   type: "Cold Food",
  // },
  // {
  //   title: "House Pickles (Vegan)",
  //   ings: "Seasonal tsukemono",
  //   image: pickles,
  //   type: "Cold Food",
  // },
  {
    title: "Tuna Tartare",
    ings: "Wild yellowfin, tamari, avocado, charcoal tempura, taro chips",
    image: null,
    type: "Cold Food",
    price: 27
  },
  {
    title: "Black Sesame Spinach",
    ings: "Spinach, black sesame, mirin",
    image: null,
    type: "Cold Food",
    price: 7
  },
  {
    title: "Cucumber Salad",
    ings: "Sliced cucumber, Micro cilantro, sesame, pickled chili",
    image: null,
    type: "Cold Food",
    price: 6
  },
];

const dessertFood = [
  {
    title: "Matcha Edamame Tiramisu",
    ings: "Matcha, edamame, lady fingers, créme",
    image: null,
    type: "Dessert",
    price: 14
  },
  // {
  //   title: "Mini Miso Mochi",
  //   ings: "Mochi, miso caramel whip, banana, kinako powder",
  //   image: null,
  //   type: "Dessert",
  // },
  // {
  //   title: "Kurogoma Cotton",
  //   ings: "Japanese cheesecake, sesame brittle, yuzu gel",
  //   image: cotton,
  //   type: "Dessert",
  // },
  {
    title: "Nori Honey Toast",
    ings: "Burnt honey, miso caramel, sweet furikake, arare, red bean butter",
    image: toast,
    type: "Dessert",
    price: 12
  },
];

const highBalls = [
  {
    title: "Suntory Toki",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 16
  },
  {
    title: "Nikka Days",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 16
  },
  {
    title: "Nikka Taketsuru",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 21
  },
  {
    title: "Nikka Coffey Grain",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 21
  },
  {
    title: "Nikka Coffee Malt",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 21
  },
  {
    title: "Nikka Miyagikyo",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 21
  },
  {
    title: "Hatozaki Pure Malt",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 22
  },
  {
    title: "Nikka Yoichi",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 22
  },
  {
    title: "Kurayoshi Pure Malt",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 22
  },
  {
    title: "Sunday's Whisky",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 22
  },
  {
    title: "Suntory World Whisky AO",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 24
  },
  {
    title: "Nikka From The Barrel",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 22
  },
  {
    title: "Hibiki Harmony",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 30
  },
  {
    title: "Nikka Miyagikyo Aromatic Yeast",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 45
  },

  {
    title: "Nikka Yoichi Aromatic Yeast",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 45
  },
  {
    title: "Kurayoshi Pure Malt 18yr",
    ings: "",
    image: null,
    type: "Japanese Highballs",
    price: 100
  },
];

const sake = [
  {
    title: "Masumi Kuro",
    ings: "Junmai Ginjo",
    image: null,
    type: "Sake",
    price: 80
  },
  {
    title: "Sanzen Omachi",
    ings: "Tok (Special) Junmai",
    image: null,
    type: "Sake",
    price: 90
  },
  {
    title: "Dewa Sansan",
    ings: "Junmai Ginjo",
    image: null,
    type: "Sake",
    price: 95
  },
  {
    title: "Yukimegami",
    ings: "Junmai Daiginjo",
    image: null,
    type: "Sake",
    price: 105
  },
  {
    title: "Miyakanbai",
    ings: "Junmai Ginjo",
    image: null,
    type: "Sake",
    price: 135
  },
  {
    title: "Zaku",
    ings: "Junmai ‘Ho No Tomo’",
    image: null,
    type: "Sake",
    price: 140
  },
  {
    title: "Miyakanbai",
    ings: "Junmai Ginjo",
    image: null,
    type: "Sake",
    price: 150
  },
  {
    title: `Kikusui "Chrysanthemum Mist"`,
    ings: "Junmai Ginjo",
    image: null,
    type: "Sake",
    price: 45
  },
  {
    title: "Nambu Bijin Green Label",
    ings: "Junmai Ginjo",
    image: null,
    type: "Sake",
    price: 60
  },
  {
    title: "Mio Sparkling Sake",
    ings: "Junmai Ginjo",
    image: null,
    type: "Sake",
    price: 40
  },
];

const beers = [
  {
    title: "Rorschach Japanese Rice Lager",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 8
  },
  {
    title: "Godspeed Yuzu Citrus Saison",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 9
  },
  {
    title: "Godspeed Ochame Green Tea IPA",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 9
  },
  {
    title: "Harmon's Non-Alcoholic Pale Ale",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 8
  },
  {
    title: "Hipster Beer",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 6
  },
  // {
  //   title: "Red, White, and bubbles",
  //   ings: "",
  //   image: null,
  //   type: "Beer/Wine",    
  //   price: 17
  // },
];

const wines = [
  {
    title: "Speri Valpolicella Classico (IT)",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 15
  },
  {
    title: "Map Maker Sauvignon Blanc (NZ)",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 15
  },
  {
    title: "Trevisana Prosecco Millesimato Extra Dry N.V.",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 14
  },
 
];


const boiler = [
  {
    title: "Rorschach Lager & Suntory Toki Whisky",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 12
  },
  {
    title: "Godspeed Green Tea IPA & Choya Plum Liqueur",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 12
  },
  {
    title: "Masumi Kuro Sake & Daiyame Premium Shochu",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 18
  },
  {
    title: "Daiyame Premium Shochu (2oz)",
    ings: "",
    image: null,
    type: "Beer/Wine",
    price: 16
  },
 
];

const Menu = ({ className }) => {
  const classesList = `${classes.main} ${className}`;
  const [selected, setSelected] = useState(1);
  const [subtitle, setSubtitle] = useState("Hot Food");
  const [cocktailImage, setCocktailImage] = useState(null);
  const selectCocktailHandler = (cocktail, image) => {
    setSelected(cocktail);
    // setSubtitle(cocktail.type);
    setCocktailImage(image);
  };
  return (
    <motion.div
      className={classesList}
      // variants={addCocktailVariants}
      initial={{
        x: "100%",
        opacity: 0,
      }}
      animate={{
        x: 0,
        opacity: 1,
        // transition: {
        //   duration: 0.5,
        // },
      }}
      exit={{
        // x: "-100%",
        opacity: 1,

        // transition: {
        //   //   type: "spring",

        //   duration: 0.5,
        // },
      }}
    >
      <div className={classes.left}>
        <div className={classes.titleContainer}>
          <h1 className={classes.title}>{subtitle}</h1>
        </div>
        {cocktailImage && (
          <img
            className={classes.background}
            src={cocktailImage}
            alt="background"
          />
        )}
      </div>
      <div className={classes.right}>
        <div className={classes.menuContainer}>
        {/* <h3>Brunch</h3> */}
          <h4 className={classes.subtitle}>Cold Appetizers</h4>
          <div className={classes.menuBox}>
            {coldFood.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 80}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 80, cocktail.image);
                    setSubtitle(cocktail.title);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Hot items</h4>
          <div className={classes.menuBox}>
            {friedFood.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 40}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 40, cocktail.image);
                    setSubtitle(cocktail.title);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Japanese Grill</h4>
          <div className={classes.menuBox}>
            {hotFood.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  active={selected === i + 1}
                  price={cocktail.price}
                  onClick={() => {
                    selectCocktailHandler(i + 1, cocktail.image);
                    setSubtitle(cocktail.title);
                  }}
                />
              );
            })}
          </div>
         
          <h4 className={classes.subtitle}>Desserts</h4>
          <div className={classes.menuBox}>
            {dessertFood.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 120}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 120, cocktail.image);
                    setSubtitle(cocktail.title);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Signature Cocktails</h4>
          <div className={classes.menuBox}>
            {/* <h1>H1-Big Title</h1>
          <h2>H2-Medium Title</h2>
          <h3>H3-Subtitle</h3>
          <h4>H4-Highlights</h4>
          <h6>P1-Medium Text Body</h6>
          <p>P2-Small Text Body</p> */}

            {cocktails.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 40}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 40, cocktail.image);
                    setSubtitle(cocktail.title);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Japanese Highballs 1.5oz</h4>
          <div className={classes.menuBox}>
            {highBalls.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 160}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 160, cocktail.image);
                    setSubtitle(cocktail.type);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Sake</h4>
          <div className={classes.menuBox}>
            {sake.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 200}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 200, cocktail.image);
                    setSubtitle(cocktail.type);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Beer</h4>
          <div className={classes.menuBox}>
            {beers.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 220}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 220, cocktail.image);
                    setSubtitle(cocktail.type);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Wine</h4>
          <div className={classes.menuBox}>
            {wines.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 250}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 250, cocktail.image);
                    setSubtitle(cocktail.type);
                  }}
                />
              );
            })}
          </div>
          <h4 className={classes.subtitle}>Boiler Makers / Shochu</h4>
          <div className={classes.menuBox}>
            {boiler.map((cocktail, i) => {
              return (
                <MenuItem
                  title={cocktail.title}
                  ings={cocktail.ings.split(", ")}
                  selected={selected}
                  price={cocktail.price}
                  active={selected === i + 1 + 270}
                  onClick={() => {
                    selectCocktailHandler(i + 1 + 270, cocktail.image);
                    setSubtitle(cocktail.type);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Menu;
